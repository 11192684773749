import { default as index2vGi1fn6ChMeta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/about/index.vue?macro=true";
import { default as _91id_93iOYoGQioHiMeta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/blog/[id].vue?macro=true";
import { default as blog_45gridc80pOYBvrlMeta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/blog/blog-grid.vue?macro=true";
import { default as indexWVNF1UbnUsMeta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/configuration/index.vue?macro=true";
import { default as indextjkx3OajaDMeta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/contact/index.vue?macro=true";
import { default as indexcp2oWBFHt9Meta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/demos/conference/index.vue?macro=true";
import { default as indexDda2SttjlqMeta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/demos/course/index.vue?macro=true";
import { default as indexwN6kId4NuRMeta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/demos/it/index.vue?macro=true";
import { default as indexVqYbcSLTlPMeta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/demos/medical/index.vue?macro=true";
import { default as indexCmxX257oM4Meta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/demos/nft/index.vue?macro=true";
import { default as indexjB3d4HZJwAMeta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/demos/payment/index.vue?macro=true";
import { default as index9UDZRlbfWMMeta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/demos/real-estate/index.vue?macro=true";
import { default as indexhWxVfFXbSJMeta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/demos/sass-demo/index.vue?macro=true";
import { default as indexa5cMLRsLIhMeta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/demos/travel/index.vue?macro=true";
import { default as indexlREz2MgBcoMeta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/helpdesk/index.vue?macro=true";
import { default as index7oXBmPQU0AMeta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/index.vue?macro=true";
import { default as indexphIUsnw1WUMeta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/login/index.vue?macro=true";
import { default as _91id_93uD4qb1JRY1Meta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/portfolio/[id].vue?macro=true";
import { default as fourcolumsoffmHE7bgvMeta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/portfolio/fourcolums.vue?macro=true";
import { default as gridhvVJCnrPWEMeta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/portfolio/grid.vue?macro=true";
import { default as threecolumskN4CPsXOsmMeta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/portfolio/threecolums.vue?macro=true";
import { default as twocolumsKKF0ygVPaTMeta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/portfolio/twocolums.vue?macro=true";
import { default as indexWnT2CiZy5tMeta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/service/index.vue?macro=true";
import { default as accordionDIB8WBvuHBMeta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/ui-components/accordion.vue?macro=true";
import { default as alertUeYDN8G3yAMeta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/ui-components/alert.vue?macro=true";
import { default as avatarJ3bTNXO4MxMeta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/ui-components/avatar.vue?macro=true";
import { default as chipxcMtMnEEGGMeta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/ui-components/chip.vue?macro=true";
import { default as dialogsdrBKUkfFw5Meta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/ui-components/dialogs.vue?macro=true";
import { default as list0HtxGV5vRXMeta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/ui-components/list.vue?macro=true";
import { default as menus8PLgZFPiNVMeta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/ui-components/menus.vue?macro=true";
import { default as rattingMZybd7QC2IMeta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/ui-components/ratting.vue?macro=true";
import { default as tabs6IjIWmrbPfMeta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/ui-components/tabs.vue?macro=true";
import { default as tooltip2o2dgNR8yWMeta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/ui-components/tooltip.vue?macro=true";
import { default as typographyFBhbhVW3DzMeta } from "C:/hnavarro/Personal/Repositorios/landing_ncr/pages/ui-components/typography.vue?macro=true";
export default [
  {
    name: index2vGi1fn6ChMeta?.name ?? "about",
    path: index2vGi1fn6ChMeta?.path ?? "/about",
    meta: index2vGi1fn6ChMeta || {},
    alias: index2vGi1fn6ChMeta?.alias || [],
    redirect: index2vGi1fn6ChMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93iOYoGQioHiMeta?.name ?? "blog-id",
    path: _91id_93iOYoGQioHiMeta?.path ?? "/blog/:id()",
    meta: _91id_93iOYoGQioHiMeta || {},
    alias: _91id_93iOYoGQioHiMeta?.alias || [],
    redirect: _91id_93iOYoGQioHiMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/blog/[id].vue").then(m => m.default || m)
  },
  {
    name: blog_45gridc80pOYBvrlMeta?.name ?? "blog-blog-grid",
    path: blog_45gridc80pOYBvrlMeta?.path ?? "/blog/blog-grid",
    meta: blog_45gridc80pOYBvrlMeta || {},
    alias: blog_45gridc80pOYBvrlMeta?.alias || [],
    redirect: blog_45gridc80pOYBvrlMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/blog/blog-grid.vue").then(m => m.default || m)
  },
  {
    name: indexWVNF1UbnUsMeta?.name ?? "configuration",
    path: indexWVNF1UbnUsMeta?.path ?? "/configuration",
    meta: indexWVNF1UbnUsMeta || {},
    alias: indexWVNF1UbnUsMeta?.alias || [],
    redirect: indexWVNF1UbnUsMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/configuration/index.vue").then(m => m.default || m)
  },
  {
    name: indextjkx3OajaDMeta?.name ?? "contact",
    path: indextjkx3OajaDMeta?.path ?? "/contact",
    meta: indextjkx3OajaDMeta || {},
    alias: indextjkx3OajaDMeta?.alias || [],
    redirect: indextjkx3OajaDMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: indexcp2oWBFHt9Meta?.name ?? "demos-conference",
    path: indexcp2oWBFHt9Meta?.path ?? "/demos/conference",
    meta: indexcp2oWBFHt9Meta || {},
    alias: indexcp2oWBFHt9Meta?.alias || [],
    redirect: indexcp2oWBFHt9Meta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/demos/conference/index.vue").then(m => m.default || m)
  },
  {
    name: indexDda2SttjlqMeta?.name ?? "demos-course",
    path: indexDda2SttjlqMeta?.path ?? "/demos/course",
    meta: indexDda2SttjlqMeta || {},
    alias: indexDda2SttjlqMeta?.alias || [],
    redirect: indexDda2SttjlqMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/demos/course/index.vue").then(m => m.default || m)
  },
  {
    name: indexwN6kId4NuRMeta?.name ?? "demos-it",
    path: indexwN6kId4NuRMeta?.path ?? "/demos/it",
    meta: indexwN6kId4NuRMeta || {},
    alias: indexwN6kId4NuRMeta?.alias || [],
    redirect: indexwN6kId4NuRMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/demos/it/index.vue").then(m => m.default || m)
  },
  {
    name: indexVqYbcSLTlPMeta?.name ?? "demos-medical",
    path: indexVqYbcSLTlPMeta?.path ?? "/demos/medical",
    meta: indexVqYbcSLTlPMeta || {},
    alias: indexVqYbcSLTlPMeta?.alias || [],
    redirect: indexVqYbcSLTlPMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/demos/medical/index.vue").then(m => m.default || m)
  },
  {
    name: indexCmxX257oM4Meta?.name ?? "demos-nft",
    path: indexCmxX257oM4Meta?.path ?? "/demos/nft",
    meta: indexCmxX257oM4Meta || {},
    alias: indexCmxX257oM4Meta?.alias || [],
    redirect: indexCmxX257oM4Meta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/demos/nft/index.vue").then(m => m.default || m)
  },
  {
    name: indexjB3d4HZJwAMeta?.name ?? "demos-payment",
    path: indexjB3d4HZJwAMeta?.path ?? "/demos/payment",
    meta: indexjB3d4HZJwAMeta || {},
    alias: indexjB3d4HZJwAMeta?.alias || [],
    redirect: indexjB3d4HZJwAMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/demos/payment/index.vue").then(m => m.default || m)
  },
  {
    name: index9UDZRlbfWMMeta?.name ?? "demos-real-estate",
    path: index9UDZRlbfWMMeta?.path ?? "/demos/real-estate",
    meta: index9UDZRlbfWMMeta || {},
    alias: index9UDZRlbfWMMeta?.alias || [],
    redirect: index9UDZRlbfWMMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/demos/real-estate/index.vue").then(m => m.default || m)
  },
  {
    name: indexhWxVfFXbSJMeta?.name ?? "demos-sass-demo",
    path: indexhWxVfFXbSJMeta?.path ?? "/demos/sass-demo",
    meta: indexhWxVfFXbSJMeta || {},
    alias: indexhWxVfFXbSJMeta?.alias || [],
    redirect: indexhWxVfFXbSJMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/demos/sass-demo/index.vue").then(m => m.default || m)
  },
  {
    name: indexa5cMLRsLIhMeta?.name ?? "demos-travel",
    path: indexa5cMLRsLIhMeta?.path ?? "/demos/travel",
    meta: indexa5cMLRsLIhMeta || {},
    alias: indexa5cMLRsLIhMeta?.alias || [],
    redirect: indexa5cMLRsLIhMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/demos/travel/index.vue").then(m => m.default || m)
  },
  {
    name: indexlREz2MgBcoMeta?.name ?? "helpdesk",
    path: indexlREz2MgBcoMeta?.path ?? "/helpdesk",
    meta: indexlREz2MgBcoMeta || {},
    alias: indexlREz2MgBcoMeta?.alias || [],
    redirect: indexlREz2MgBcoMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/helpdesk/index.vue").then(m => m.default || m)
  },
  {
    name: index7oXBmPQU0AMeta?.name ?? "index",
    path: index7oXBmPQU0AMeta?.path ?? "/",
    meta: index7oXBmPQU0AMeta || {},
    alias: index7oXBmPQU0AMeta?.alias || [],
    redirect: index7oXBmPQU0AMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexphIUsnw1WUMeta?.name ?? "login",
    path: indexphIUsnw1WUMeta?.path ?? "/login",
    meta: indexphIUsnw1WUMeta || {},
    alias: indexphIUsnw1WUMeta?.alias || [],
    redirect: indexphIUsnw1WUMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93uD4qb1JRY1Meta?.name ?? "portfolio-id",
    path: _91id_93uD4qb1JRY1Meta?.path ?? "/portfolio/:id()",
    meta: _91id_93uD4qb1JRY1Meta || {},
    alias: _91id_93uD4qb1JRY1Meta?.alias || [],
    redirect: _91id_93uD4qb1JRY1Meta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/portfolio/[id].vue").then(m => m.default || m)
  },
  {
    name: fourcolumsoffmHE7bgvMeta?.name ?? "portfolio-fourcolums",
    path: fourcolumsoffmHE7bgvMeta?.path ?? "/portfolio/fourcolums",
    meta: fourcolumsoffmHE7bgvMeta || {},
    alias: fourcolumsoffmHE7bgvMeta?.alias || [],
    redirect: fourcolumsoffmHE7bgvMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/portfolio/fourcolums.vue").then(m => m.default || m)
  },
  {
    name: gridhvVJCnrPWEMeta?.name ?? "portfolio-grid",
    path: gridhvVJCnrPWEMeta?.path ?? "/portfolio/grid",
    meta: gridhvVJCnrPWEMeta || {},
    alias: gridhvVJCnrPWEMeta?.alias || [],
    redirect: gridhvVJCnrPWEMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/portfolio/grid.vue").then(m => m.default || m)
  },
  {
    name: threecolumskN4CPsXOsmMeta?.name ?? "portfolio-threecolums",
    path: threecolumskN4CPsXOsmMeta?.path ?? "/portfolio/threecolums",
    meta: threecolumskN4CPsXOsmMeta || {},
    alias: threecolumskN4CPsXOsmMeta?.alias || [],
    redirect: threecolumskN4CPsXOsmMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/portfolio/threecolums.vue").then(m => m.default || m)
  },
  {
    name: twocolumsKKF0ygVPaTMeta?.name ?? "portfolio-twocolums",
    path: twocolumsKKF0ygVPaTMeta?.path ?? "/portfolio/twocolums",
    meta: twocolumsKKF0ygVPaTMeta || {},
    alias: twocolumsKKF0ygVPaTMeta?.alias || [],
    redirect: twocolumsKKF0ygVPaTMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/portfolio/twocolums.vue").then(m => m.default || m)
  },
  {
    name: indexWnT2CiZy5tMeta?.name ?? "service",
    path: indexWnT2CiZy5tMeta?.path ?? "/service",
    meta: indexWnT2CiZy5tMeta || {},
    alias: indexWnT2CiZy5tMeta?.alias || [],
    redirect: indexWnT2CiZy5tMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/service/index.vue").then(m => m.default || m)
  },
  {
    name: accordionDIB8WBvuHBMeta?.name ?? "ui-components-accordion",
    path: accordionDIB8WBvuHBMeta?.path ?? "/ui-components/accordion",
    meta: accordionDIB8WBvuHBMeta || {},
    alias: accordionDIB8WBvuHBMeta?.alias || [],
    redirect: accordionDIB8WBvuHBMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/ui-components/accordion.vue").then(m => m.default || m)
  },
  {
    name: alertUeYDN8G3yAMeta?.name ?? "ui-components-alert",
    path: alertUeYDN8G3yAMeta?.path ?? "/ui-components/alert",
    meta: alertUeYDN8G3yAMeta || {},
    alias: alertUeYDN8G3yAMeta?.alias || [],
    redirect: alertUeYDN8G3yAMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/ui-components/alert.vue").then(m => m.default || m)
  },
  {
    name: avatarJ3bTNXO4MxMeta?.name ?? "ui-components-avatar",
    path: avatarJ3bTNXO4MxMeta?.path ?? "/ui-components/avatar",
    meta: avatarJ3bTNXO4MxMeta || {},
    alias: avatarJ3bTNXO4MxMeta?.alias || [],
    redirect: avatarJ3bTNXO4MxMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/ui-components/avatar.vue").then(m => m.default || m)
  },
  {
    name: chipxcMtMnEEGGMeta?.name ?? "ui-components-chip",
    path: chipxcMtMnEEGGMeta?.path ?? "/ui-components/chip",
    meta: chipxcMtMnEEGGMeta || {},
    alias: chipxcMtMnEEGGMeta?.alias || [],
    redirect: chipxcMtMnEEGGMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/ui-components/chip.vue").then(m => m.default || m)
  },
  {
    name: dialogsdrBKUkfFw5Meta?.name ?? "ui-components-dialogs",
    path: dialogsdrBKUkfFw5Meta?.path ?? "/ui-components/dialogs",
    meta: dialogsdrBKUkfFw5Meta || {},
    alias: dialogsdrBKUkfFw5Meta?.alias || [],
    redirect: dialogsdrBKUkfFw5Meta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/ui-components/dialogs.vue").then(m => m.default || m)
  },
  {
    name: list0HtxGV5vRXMeta?.name ?? "ui-components-list",
    path: list0HtxGV5vRXMeta?.path ?? "/ui-components/list",
    meta: list0HtxGV5vRXMeta || {},
    alias: list0HtxGV5vRXMeta?.alias || [],
    redirect: list0HtxGV5vRXMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/ui-components/list.vue").then(m => m.default || m)
  },
  {
    name: menus8PLgZFPiNVMeta?.name ?? "ui-components-menus",
    path: menus8PLgZFPiNVMeta?.path ?? "/ui-components/menus",
    meta: menus8PLgZFPiNVMeta || {},
    alias: menus8PLgZFPiNVMeta?.alias || [],
    redirect: menus8PLgZFPiNVMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/ui-components/menus.vue").then(m => m.default || m)
  },
  {
    name: rattingMZybd7QC2IMeta?.name ?? "ui-components-ratting",
    path: rattingMZybd7QC2IMeta?.path ?? "/ui-components/ratting",
    meta: rattingMZybd7QC2IMeta || {},
    alias: rattingMZybd7QC2IMeta?.alias || [],
    redirect: rattingMZybd7QC2IMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/ui-components/ratting.vue").then(m => m.default || m)
  },
  {
    name: tabs6IjIWmrbPfMeta?.name ?? "ui-components-tabs",
    path: tabs6IjIWmrbPfMeta?.path ?? "/ui-components/tabs",
    meta: tabs6IjIWmrbPfMeta || {},
    alias: tabs6IjIWmrbPfMeta?.alias || [],
    redirect: tabs6IjIWmrbPfMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/ui-components/tabs.vue").then(m => m.default || m)
  },
  {
    name: tooltip2o2dgNR8yWMeta?.name ?? "ui-components-tooltip",
    path: tooltip2o2dgNR8yWMeta?.path ?? "/ui-components/tooltip",
    meta: tooltip2o2dgNR8yWMeta || {},
    alias: tooltip2o2dgNR8yWMeta?.alias || [],
    redirect: tooltip2o2dgNR8yWMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/ui-components/tooltip.vue").then(m => m.default || m)
  },
  {
    name: typographyFBhbhVW3DzMeta?.name ?? "ui-components-typography",
    path: typographyFBhbhVW3DzMeta?.path ?? "/ui-components/typography",
    meta: typographyFBhbhVW3DzMeta || {},
    alias: typographyFBhbhVW3DzMeta?.alias || [],
    redirect: typographyFBhbhVW3DzMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repositorios/landing_ncr/pages/ui-components/typography.vue").then(m => m.default || m)
  }
]